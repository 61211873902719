import axios from 'axios';
import { useState, useCallback } from 'react';
import streamSaver from 'streamsaver';

// Create an axios instance with default configurations
// const axiosInstance = axios.create({
//   baseURL: process.env.REACT_APP_API_URL,
//   timeout: 10000,
//   headers: {
//     'Content-Type': 'application/json',
//     'x-access-token': localStorage.getItem('accessToken'),
//     'x-id-token': localStorage.getItem('idToken'),
//   },
// });
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000,
  headers: {
    'x-access-token': localStorage.getItem('accessToken'),
    'x-id-token': localStorage.getItem('idToken'),
  },
});

// API wrapper function with retry logic
const apiCall = async (
  method,
  url,
  data = null,
  params = null,
  retries = 3,
  type
) => {
  for (let attempt = 1; attempt <= retries; attempt++) {
    if (type === 'media') {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + '/' + url,
          {
            method: method,
            headers: {
              'Content-Type': `application/json`,
              'x-access-token': localStorage.getItem('accessToken'),
              'x-id-token': localStorage.getItem('idToken'),
            },
            body: data && method === 'POST' ? JSON.stringify(data) : undefined,
          }
        );
        return response;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.warn('Unauthorized access - logging out');
          return;
        }

        if (attempt === retries) {
          console.error('API call error after multiple attempts:', error);
          throw error; // Rethrow error after final attempt
        }
        console.warn(
          `API call failed, retrying attempt ${attempt} of ${retries}...`
        );
      }
    } else {
      try {
        const headers = {
          'Content-Type':
            type === 'multipart/form-data'
              ? 'multipart/form-data'
              : 'application/json', // Default to 'application/json'
          'x-access-token': localStorage.getItem('accessToken'),
          'x-id-token': localStorage.getItem('idToken'),
        };
        const response = await axiosInstance({
          method,
          url,
          data,
          params,
          headers,
        });
        return response.data;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.warn('Unauthorized access - logging out');
          return;
        }

        if (attempt === retries) {
          console.error('API call error after multiple attempts:', error);
          throw error; // Rethrow error after final attempt
        }
        console.warn(
          `API call failed, retrying attempt ${attempt} of ${retries}...`
        );
      }
    }
  }
};
const apiCallsForStream = async (method, url, data = null, type, options) => {
  const config = {
    method,
    url,
    headers: {
      'x-access-token': localStorage.getItem('accessToken'),
      'x-id-token': localStorage.getItem('idToken'),
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await fetch(config.url, {
      method: config.method,
      headers: config.headers,
      body: data && method === 'POST' ? JSON.stringify(data) : undefined,
    });

    // Check if the response is JSON
    const isJson = response.headers
      .get('Content-Type')
      ?.includes('application/json');

    if (isJson) {
      return await response.json();
    }

    // Get the response stream
    const responseStream = response.body;

    if (responseStream) {
      if (options && options.onStreamStart) {
        options.onStreamStart();
      }

      // Ensure WritableStream is available
      if (!window.WritableStream) {
        streamSaver.WritableStream = WritableStream;
        window.WritableStream = WritableStream;
      }

      // Extract filename and extension from headers
      const filenameSplit = response.headers
        .get('Content-Disposition')
        ?.split('filename=');
      const extensionSplit = response.headers.get('Content-Type')?.split('/');

      const extension =
        extensionSplit && extensionSplit[1] ? extensionSplit[1] : '.txt';
      const filename =
        filenameSplit && filenameSplit[1]
          ? filenameSplit[1]
          : 'file' + extension;

      // Create a writable stream
      const fileStream = streamSaver.createWriteStream(filename);

      // More optimized
      if (responseStream.pipeTo) {
        return responseStream.pipeTo(fileStream);
      }

      window.writer = fileStream.getWriter();

      const reader = responseStream.getReader();
      const pump = () =>
        reader.read().then((res) => {
          return res.done
            ? window.writer.close()
            : window.writer.write(res.value).then(pump);
        });

      pump();
    }

    return null;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      throw error;
    }
  }
};

export const useApiCall = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const callApi = useCallback(
    async (method, url, data = {}, params = null, type) => {
      setLoading(true);
      setError(null);

      try {
        let result;

        if (type === 'streamDownload') {
          result = await apiCallsForStream(method, url, data, type);
        } else {
          result = await apiCall(method, url, data, params, 3, type);
        }

        setLoading(false);
        return result;
      } catch (err) {
        setError(err.message);
        setLoading(false);
        throw err;
      }
    },
    []
  );

  return { callApi, loading, error };
};
