import React from 'react';

const PageNotFound = () => (
  <div className='flex flex-col items-center justify-center h-screen'>
    <h1 className='text-3xl font-bold mb-4'>404 - Page Not Found</h1>
    <p className='text-gray-600 mb-4'>
      The page you are looking for does not exist.
    </p>
    <a href='/dashboard' className='text-blue-500 hover:underline text-lg'>
      Go back to Dashboard
    </a>
  </div>
);
export default PageNotFound;
