import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

export function Auth0ProviderWithNavigate({ children, orgDetails }) {
  const navigate = useNavigate();
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const redirectUri = window.location.origin;
  const authAudience = process.env.REACT_APP_AUTH0_AUDIENCE;
  const onRedirectCallback = (appState) => {
    navigate('/dashboard');
  };

  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        //  redirect_uri: redirectUri,
        redirect_uri: window.location.origin,
        scope: 'offline_access openid profile email ',
        grant_type: 'password',
        audience: authAudience,
        organization: process.env.REACT_APP_AUTH0_ORGID,
        // organization: orgDetails?.orgId,
      }}
      onRedirectCallback={onRedirectCallback}
      cacheLocation='localstorage'
      useRefreshTokens={true}
      // onRedirectCallback={() => onRedirectCallback("/surface")}
    >
      {children}
    </Auth0Provider>
  );
}
