import React from 'react';

const Dashboard = React.lazy(() => import('../pages/Dashboard/Dashboard'));
const CreateOrg = React.lazy(() => import('../pages/CreateOrg/CreateOrg'));
const ManageAdmins = React.lazy(() =>
  import('../pages/ManageAdmins/ManageAdmins')
);
const EmployeeDataDownload = React.lazy(() =>
  import('../pages/EmployeeDataDownload/EmployeeDataDownload')
);
const adminDetails = localStorage?.getItem('adminDetails');
const storedAdminDetails = adminDetails ? JSON.parse(adminDetails) : null;
const isSuperAdmin = storedAdminDetails?.data?.isSuperAdmin;
export const routes = [
  {
    key: 'DashboardTab',
    name: 'DashboardTab',
    route: '/dashboard',
    component: <Dashboard />,
  },
  {
    key: 'EditOrgTab',
    name: 'EditOrgTab',
    route: '/edit-org/:orgId',
    component: <CreateOrg />,
  },
  {
    key: 'downloadEmployeeData',
    name: 'downloadEmployeeData',
    route: '/download/file-cache/:orgId/:id',
    component: <EmployeeDataDownload />,
  },
  ...(isSuperAdmin
    ? [
        {
          key: 'ManageAdmins',
          name: 'ManageAdmins',
          route: '/manage-admins',
          component: <ManageAdmins />,
        },
        {
          key: 'CreateOrgTab',
          name: 'CreateOrgTab',
          route: '/create-org',
          component: <CreateOrg />,
        },
      ]
    : []),
];
