import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from './components/Layout/Layout';
import { routes } from './routes/routes';
import { useAuth0 } from '@auth0/auth0-react';
import LoadingSpinner from './components/LoadingSpinner/LoadingSpinner';
import PageNotFound from './components/PageNotFound/PageNotFound';
import { COCKPIT_V3 } from './config/constants';
import { useApiCall } from './apiClient/apiClient';

const App = ({ orgDetails }) => {
  const {
    isAuthenticated,
    user,
    getAccessTokenSilently,
    getIdTokenClaims,
    loginWithRedirect,
    isLoading,
    error,
    logout,
  } = useAuth0();
  const navigate = useNavigate();
  const [loadingTokens, setLoadingTokens] = useState(true);
  const [tokens, setTokens] = useState({ accessToken: null, xIdAccess: null });
  const storedIdToken = localStorage?.getItem('idToken');
  const storedAccessToken = localStorage?.getItem('accessToken');
  const storedAdminDetails = localStorage?.getItem('adminDetails');
  useEffect(() => {
    if (storedIdToken && storedAccessToken) {
      setLoadingTokens(false);
    }
  }, [storedIdToken, storedAccessToken]);
  const { callApi } = useApiCall();
  const fetchAdminDetails = async (data) => {
    try {
      const responseData = await callApi(
        'POST',
        `${COCKPIT_V3}/admin-details`,
        {
          id_token: data,
        }
      );
      localStorage.setItem(
        'adminDetails',
        JSON.stringify({
          data: responseData?.data,
          isDataStored: true,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTokens = async () => {
    await getAccessTokenSilently()
      .then((token) => {
        if (token.length > 0) {
          localStorage.setItem('accessToken', token);
          setTokens({ ...tokens, accessToken: token });
        }
      })
      .catch((err) => {
        loginWithRedirect({
          appState: {
            returnTo: `${window.location.origin}`,
          },
        });
        console.log(err);
      });

    await getIdTokenClaims()
      .then((claims) => {
        if (claims.__raw.length > 0) {
          setTokens({ ...tokens, xIdAccess: claims.__raw });
          localStorage.setItem('idToken', claims.__raw);
        }
      })
      .catch((err) => {
        console.log('Something went wrong', err);
      });
    setLoadingTokens(false);
  };

  useEffect(() => {
    setTimeout(() => {
      fetchTokens();
    }, 1000);
  }, []);
  useEffect(() => {
    if (
      (tokens?.idToken || storedIdToken) &&
      !storedAdminDetails?.isDataStored
    ) {
      fetchAdminDetails(tokens?.idToken || storedIdToken);
    }
  }, [tokens?.idToken, storedIdToken, storedAdminDetails]);
  if (loadingTokens) {
    return (
      <div className='flex flex-col justify-center items-center h-screen w-full'>
        <div className='w-64'>
          <LoadingSpinner />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className='flex flex-col justify-center items-center h-screen w-full'>
        <p className='mb-4'>Oops! Something went wrong.</p>
        <button
          onClick={() => window.location.reload()}
          className='px-4 py-2 bg-blue-500 text-white rounded-md'
        >
          Retry Now
        </button>
      </div>
    );
  }

  if (isAuthenticated && !isLoading && !error && !loadingTokens) {
    return (
      <Layout>
        <ToastContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          theme='light'
        />
        <Routes>
          {routes.map((item) => (
            <Route
              key={item?.key}
              path={item?.route}
              element={item?.component}
            />
          ))}
          <Route path='/' element={<Navigate to='/dashboard' replace />} />
          <Route path='*' element={<PageNotFound />} />
        </Routes>
      </Layout>
    );
  }
};

export default App;
